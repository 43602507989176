@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");
@import url("https://fonts.googleapis.com/css?family=VT323");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

body {
  opacity: 0.85;
  z-index: 10;
  color: white;
  font-family: "Press Start 2P", "VT323";
}

.moving-background {
  z-index: -1;
  background-image: url("./assets/background/bg.gif");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 40% 40%;
}

.glass {
  backdrop-filter: blur(15px);
}
